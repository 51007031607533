import Vue from 'vue';

const SiteHeader = () => import(/* webpackPrefetch: true, webpackChunkName: 'site-header' */ './components/SiteHeader');
const AccordionItem = () => import(/* webpackChunkName: 'accordion-item' */ './components/AccordionItem');

new Vue({
    el: '#app',
    components: {
        SiteHeader,
        AccordionItem,
    }
})